import { ProductUtils } from '../../utils'

export const product = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            if (
                action.payload.model.generalConfig
                    ?.productLayer?.hideRelatedProducts
            ) {
                return ProductUtils.removeRelatedProducts(
                    action.payload.model.product || state,
                )
            }
            return action.payload.model.product || state
        default:
            return state
    }
}
