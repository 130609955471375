import styled from 'styled-components'
import { Input, getStyles } from '@conversionbuddy/Layout'
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useExternalNavigate } from '../../hooks/useExternalNavigate'
import { useTranslation } from '../../hooks/useTranslation'
import { useQuery } from '../../hooks/useQuery'

const StyledSearchContainer = styled.div`
    ${getStyles(['search.container'])}
`

const StyledSearchForm = styled.form`
    ${getStyles(['search.form'])}
`

const StyledSearchIcon = styled.div`
    ${getStyles(['search.icon'])}
`

const StyledSearchInput = styled(Input)`
    ${getStyles(['search.input'])}
`

export const Search = ({ internal }) => {
    const inputRef = useRef()
    const [navigate, TrackingTypes] = useExternalNavigate()
    const [query, setQuery] = useQuery()
    const initialValue = internal ? query['q'] : ''
    const [value, setValue] = useState(initialValue)
    const link = useSelector(
        (state) => state.links?.search?.url,
    )

    const placeholder = useTranslation('search.placeholder')

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleSubmit = (e) => {
        inputRef.current?.blur()
        e.preventDefault()
        if (value) {
            if (!internal) {
                const target = link.replace(
                    'QUERYSTRING',
                    value,
                )

                return navigate(target, {
                    type: TrackingTypes.CROSS_OUT_INFO,
                })
            }

            setQuery(
                {
                    ...query,
                    q: value,
                    offset: 0,
                },
                TrackingTypes.CLICK_INTERNAL,
                null,
                '/search',
            )
        }
    }

    return (
        <StyledSearchContainer>
            <StyledSearchForm onSubmit={handleSubmit}>
                <StyledSearchInput
                    ref={inputRef}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                />
                <StyledSearchIcon
                    data-testid='search_icon'
                    onClick={handleSubmit}
                />
            </StyledSearchForm>
        </StyledSearchContainer>
    )
}

Search.propTypes = {
    internal: PropTypes.bool,
}

Search.defaultProps = {
    internal: false,
}
