import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, getStyles } from '@conversionbuddy/Layout'
import { cutTextByDots, uniq } from '../../../../utils'

export const calculateDescriptionAltStyles = ({
    dims,
    position,
}) => {
    if (!position || !dims) {
        return {
            display: 'none',
        }
    }

    return {
        left: `${position.left}px`,
        top: `${position.top}px`,
        width: `${dims.width}px`,
        maxHeight: `${dims.height}px`,
        whiteSpace: 'pre-line',
    }
}

const ProductLayerDetailsDescription = styled(Box)`
    ${getStyles(['productLayer.details.description'])}
`

const ProductLayerDetailsDescriptionAltContent = styled.div`
    ${getStyles(['productLayer.details.description.alt'])}
`

// pending:styles in defaultheme
const ProductLayerDetailsDescriptionList = styled.ul`
    ${(props) =>
        getStyles([
            'productLayer.details.description.list',
            props.isBulletPoints &&
                'productLayer.details.description.list.bulletPoints',
        ])(props)}
`
const ProductLayerDetailsDescriptionListItem = styled.li`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ProductLayerDetailsDescriptionAlt = ({
    children,
}) => {
    const [state, setState] = useState({})
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (typeof window !== 'undefined') {
                setState({
                    position: {
                        left: e.clientX + 10,
                        top: e.clientY + 10,
                    },
                    dims: {
                        width: Math.min(
                            window.innerWidth -
                                e.clientX -
                                30,
                            500,
                        ),
                        height:
                            window.innerHeight -
                            e.clientY -
                            30,
                    },
                })
            }
        }
        if (typeof window !== 'undefined') {
            window.addEventListener(
                'mousemove',
                handleMouseMove,
            )
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener(
                    'mousemove',
                    handleMouseMove,
                )
            }
        }
    }, [])

    const style = calculateDescriptionAltStyles(state)

    return (
        <ProductLayerDetailsDescriptionAltContent
            style={style}
        >
            {children}
        </ProductLayerDetailsDescriptionAltContent>
    )
}

ProductLayerDetailsDescriptionAlt.propTypes = {
    children: PropTypes.node.isRequired,
}

export const Description = ({
    maxHoverTextLength,
    maxRows,
    maxTextLength,
    minHoverTextLength,
    onClick,
    product,
}) => {
    const usps = useSelector(
        (state) => state.general.uniqueSellingPoints,
    )

    const [isAltTextVisible, setIsAltTextVisible] =
        useState(false)

    const {
        altDescription,
        descriptionLength,
        isBulletPoints,
        truncatedDescription,
    } = useMemo(() => {
        const { bulletPoints, shortDescription } = product
        if (!bulletPoints && !shortDescription) {
            return {
                truncatedDescription: [],
                altDescription: '',
                descriptionLength: 0,
                isBulletPoints: false,
            }
        }

        const isBulletPoints = Boolean(bulletPoints)

        const fullDescription = bulletPoints || [
            usps
                ? `${shortDescription}<br>${usps}`
                : shortDescription,
        ]

        const description = uniq(
            fullDescription.flatMap(
                (entry) => entry.split(/<br\s*\/?>/i), // covers also self-closing tags
            ),
        )

        const descriptionLength =
            description.join('').length

        const truncatedDescription = description
            .slice(0, maxRows)
            .map((text) =>
                cutTextByDots(text, maxTextLength),
            )

        const altDescription = cutTextByDots(
            description.join('\n'),
            maxHoverTextLength,
            minHoverTextLength,
        ).split('\n')

        return {
            truncatedDescription,
            altDescription,
            descriptionLength,
            isBulletPoints,
        }
    }, [
        maxHoverTextLength,
        minHoverTextLength,
        maxRows,
        maxTextLength,
        product,
        usps,
    ])

    const handleMouseEnter = () => {
        if (descriptionLength >= maxTextLength) {
            setIsAltTextVisible(true)
        }
    }

    const handleMouseLeave = () => {
        setIsAltTextVisible(false)
    }

    return truncatedDescription.length > 0 ? (
        <ProductLayerDetailsDescription
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isAltTextVisible && (
                <ProductLayerDetailsDescriptionAlt>
                    <ProductLayerDetailsDescriptionList
                        isBulletPoints={isBulletPoints}
                    >
                        {altDescription.map((row) => (
                            <ProductLayerDetailsDescriptionListItem
                                key={row}
                            >
                                {row}
                            </ProductLayerDetailsDescriptionListItem>
                        ))}
                    </ProductLayerDetailsDescriptionList>
                </ProductLayerDetailsDescriptionAlt>
            )}
            <ProductLayerDetailsDescriptionList
                isBulletPoints={isBulletPoints}
            >
                {truncatedDescription.map((row) => (
                    <ProductLayerDetailsDescriptionListItem
                        key={row}
                    >
                        {row}
                    </ProductLayerDetailsDescriptionListItem>
                ))}
            </ProductLayerDetailsDescriptionList>
        </ProductLayerDetailsDescription>
    ) : null
}

Description.propTypes = {
    maxRows: PropTypes.number,
    maxHoverTextLength: PropTypes.number,
    minHoverTextLength: PropTypes.number,
    maxTextLength: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
}

Description.defaultProps = {
    maxRows: 2,
    maxTextLength: 80,
    maxHoverTextLength: 600,
    minHoverTextLength: 500,
}
