import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, getStyles } from '@conversionbuddy/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { Description } from './Description'
import { Buttons } from './Buttons'
import { Prices } from './Prices'
import { Attributes } from '../../Layout/Attributes'
import { Rating } from '../../Layout/Rating'
import { PriceInfo } from './PriceInfo'
import { Placeholder } from '../../Layout/Placeholder'
import { Availability } from './Availability'

import {
    InternalParamsConstants,
    useInternalParamToggle,
} from '../../../hooks/useInternalParams'
import { selectSku } from '../../../store/actions/activeSku'
import { ProductColors } from '../../Layout/Colors'
import { TrackingTypes } from '../../../hooks/useTracking'
import { EnergyEfficiencyClass } from '../../EnergyEfficiencyClass'

const ProductLayerDetails = styled(Box)`
    flex: 1 0 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    @media screen and (min-width: 900px) {
        max-width: 50%;
        padding-top: 3.5rem;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 4px;
    }
`

const ProductLayerDetailsBody = styled(Box)`
    padding: 0.75rem;
    @media screen and (min-width: 900px) {
        padding: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ProductLayerDetailsTitle = styled(Box)`
    margin-bottom: 0.6rem;
    line-height: 1.5;
    height: auto;
    ${getStyles([['productLayer', 'details', 'title']])}
`

const ProductLayerPricesContainer = styled.div`
    ${getStyles(['productLayer.prices.container'])}
`

const StyledProductLayerAttributes = styled.div`
    ${getStyles(['productLayer.details.attributes'])}

    & select {
        border: 1px solid black !important;
        border-radius: 2px;
    }
`

const types = {
    onClick: PropTypes.func.isRequired,
    onDeselectRelatedProduct: PropTypes.func.isRequired,
    onSelectRelatedProduct: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    activeSku: PropTypes.object,
    dimensions: PropTypes.object,
}

export const Details = ({
    activeSku,
    dimensions,
    onClick,
    onDeselectRelatedProduct,
    onSelectRelatedProduct,
    product,
}) => {
    const device = useSelector((state) => state.device)
    const buy = useInternalParamToggle(
        InternalParamsConstants.BUY,
    )
    const hasShoppingCart =
        product.skus.every((sku) => sku.addToCartUrl) && buy
    const dispatch = useDispatch()

    const handleSelectSku = (sku) => {
        !hasShoppingCart &&
        product.uniqueCharacteristics.length < 2
            ? onClick({
                  sku,
              })
            : dispatch(selectSku(sku))
    }

    const handleClickOut = (
        _,
        trackingTypeOut,
        ignoreShoppingCart = false,
    ) => {
        onClick({
            addToCart: ignoreShoppingCart
                ? null
                : hasShoppingCart,
            trackingTypeOut,
        })
    }

    const handleClickColorVariant = (relatedProduct) => {
        onClick({
            targetUrl: relatedProduct.url,
            sku: {
                sku: relatedProduct.sku,
            },
            trackingTypeOut:
                TrackingTypes.CLICK_OUT_RELATED_PRODUCT,
            relatedProduct: {
                id: relatedProduct.id,
            },
        })
    }

    const handleDeselectSku = () => {
        dispatch(selectSku(null))
    }

    const hasColorVariants = useMemo(
        () => product.relatedProducts?.byColor?.length > 0,
        [product],
    )

    return (
        <ProductLayerDetails>
            <ProductLayerDetailsBody>
                <Placeholder id='beforeProductLayerTitle' />
                <ProductLayerDetailsTitle>
                    {product.title}
                </ProductLayerDetailsTitle>

                <ProductLayerPricesContainer>
                    <Prices />
                    <PriceInfo />
                </ProductLayerPricesContainer>
                <EnergyEfficiencyClass
                    layer
                    activeSku={activeSku}
                    product={product}
                />
                <Rating value={product.ratingValue} />

                <Description
                    maxRows={2}
                    maxTextLength={80}
                    product={product}
                    onClick={handleClickOut}
                />
                <ProductColors
                    hasLabel
                    containerStyle={{
                        marginTop: 10,
                    }}
                    dimensions={{
                        width:
                            device === 'DESKTOP'
                                ? // 900 / 2 - 60
                                  // hälfte des layers minus 2 * 30 padding
                                  390
                                : dimensions.width,
                        height:
                            device === 'DESKTOP' ? 80 : 50,
                    }}
                    product={product}
                    onClick={handleClickColorVariant}
                    onDeselect={onDeselectRelatedProduct}
                    onSelect={onSelectRelatedProduct}
                />
                <StyledProductLayerAttributes>
                    <Attributes
                        layer
                        activeSku={activeSku}
                        product={product}
                        type={
                            hasColorVariants
                                ? 'select'
                                : undefined
                        }
                        onDeselect={handleDeselectSku}
                        onSelect={handleSelectSku}
                    />
                </StyledProductLayerAttributes>
                <Availability activeSku={activeSku} />
                <Buttons
                    shoppingCart={
                        activeSku && hasShoppingCart
                    }
                    onClickOut={handleClickOut}
                />
            </ProductLayerDetailsBody>
        </ProductLayerDetails>
    )
}

Details.propTypes = types
Details.defaultProps = {
    activeSku: null,
    dimensions: {
        width: 0,
        height: 0,
    },
}
