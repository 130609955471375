import { clearNullishValues, omitKeys } from '../../utils'

export const ProductUtils = {
    removeShortDescription: (product) => {
        return (
            product && {
                ...product,
                shortDescription: null,
            }
        )
    },
    removeNullish: (product) => {
        return product && clearNullishValues(product)
    },
    removeRelatedProducts: (product) =>
        omitKeys(product, ['relatedProducts']),
}

export const isFilledProductsList = (products) =>
    products.some(
        (p) => p.categoryPath !== products[0].categoryPath,
    )
