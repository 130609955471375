import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { darken } from 'polished'
import useOnClickOutside from 'use-onclickoutside'
import { Icon, Box } from '@conversionbuddy/Layout'

const StyledSelect = styled.select`
    position: relative;
    width: 100%;
    height: 3rem;
    appearance: none;
    border-color: ${(props) => props.borderColor};
    padding-left: 0.5rem;
    padding-right: ${(props) =>
        props.removeable ? '5.5rem' : '2.5rem'};
    background-color: white;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    :focus {
        outline: 0;
        border-color: ${(props) =>
            darken(0.4, props.borderColor)};
    }
`
const StyledOption = styled.option``

const SelectWrapper = styled.div`
    position: relative;
    margin-top: ${(props) => props.marginTop};
`

const SelectIconCaretWrapper = styled(Box)`
    position: absolute;
    right: 0;
    width: 3rem;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`

const SelectIconCrossWrapper = styled.div`
    position: absolute;
    right: 3rem;
    width: 2.5rem;
    height: 100%;
    z-index: 10;
    align-items: center;
    justify-content: center;
    border-right: 1px solid black;
    display: ${(props) =>
        props.visible ? 'flex' : 'none'};
`

export const Select = ({
    label,
    options,
    onChange,
    removeable,
    value,
    styles,
}) => {
    const handleChange = (e) => {
        onChange(e.target.value)
    }

    const handleRemove = (e) => {
        e.stopPropagation()
        e.preventDefault()
        onChange('-1')
    }

    const hasSelection = () => {
        return (
            value !== '-1' &&
            value !== null &&
            value !== undefined
        )
    }
    const getBorderColor = () => {
        return styles?.borderColor || 'black'
    }

    const getMarginTop = () => {
        return styles?.marginTop || 0
    }

    const renderOptions = () =>
        options.map((option) => {
            return (
                <StyledOption
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                >
                    {option.value}
                </StyledOption>
            )
        })

    const ref = useRef(null)
    useOnClickOutside(ref, () => {})

    return (
        <SelectWrapper marginTop={getMarginTop()}>
            <SelectIconCaretWrapper>
                <Icon
                    variant='Caret'
                    color='#000000'
                    direction='bottom'
                    dimensions={[10, 10]}
                />
            </SelectIconCaretWrapper>
            {removeable ? (
                <SelectIconCrossWrapper
                    borderColor={getBorderColor()}
                    visible={hasSelection()}
                    onClick={handleRemove}
                >
                    <Icon
                        variant='Cross'
                        strokeWidth={1}
                        color='#000000'
                        dimensions={[10, 10]}
                    />
                </SelectIconCrossWrapper>
            ) : null}
            <StyledSelect
                onChange={handleChange}
                value={value || '-1'}
                borderColor={getBorderColor()}
                removeable={removeable}
            >
                <StyledOption value='-1'>
                    {label}
                </StyledOption>
                {renderOptions()}
            </StyledSelect>
        </SelectWrapper>
    )
}

Select.propTypes = {
    removeable: PropTypes.bool,
    label: PropTypes.node,
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    styles: PropTypes.object,
}

Select.defaultProps = {}
