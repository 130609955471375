import { Box } from '@conversionbuddy/Layout'
import React, { useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import { DesktopControls } from './components/DesktopControls'
import { MobileControls } from './components/MobileControls'
import { useSelector } from 'react-redux'

export const Controls = () => {
    const [activeFilter, setActiveFilter] = useState(null)

    const total = useSelector((state) => state.meta.total)

    const ref = useRef(null)
    useOnClickOutside(ref, () => {
        setActiveFilter(null)
    })

    const handleFiltersClick = (filter) => {
        setActiveFilter((prevActiveFilter) => {
            if (prevActiveFilter?.name === filter.name) {
                return null
            }
            return filter
        })
    }

    const handleSortingClick = () => {
        setActiveFilter((prevActiveFilter) => {
            if (prevActiveFilter === 'sort') {
                return null
            }
            return 'sort'
        })
    }

    return (
        total > 0 && (
            <ThemeProvider
                theme={(theme) => {
                    return {
                        ...theme,
                        grid: {
                            ...theme.grid,
                            gutter: 0,
                        },
                    }
                }}
            >
                <Box
                    ref={ref}
                    aria-label='Controls'
                    role='region'
                >
                    <DesktopControls
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        onFiltersClick={handleFiltersClick}
                        onSortingClick={handleSortingClick}
                    />
                    <MobileControls
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        onFiltersClick={handleFiltersClick}
                        onSortingClick={handleSortingClick}
                    />
                </Box>
            </ThemeProvider>
        )
    )
}
