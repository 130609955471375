import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getStyles } from '@conversionbuddy/Layout'
import { Eyecatchers } from '../../Layout/Eyecatchers'
import { Thumbnails } from './Thumbnails'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useSelector } from 'react-redux'
import { Swiper } from '../../Layout/Swiper'

const useLayerMediaQuery = () => {
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const isTablet = useMediaQuery(
        '(min-width: 576px) and (max-width: 899px)',
    )
    const isMobile = useMediaQuery(
        '(min-width: 0px) and (max-width: 575px)',
    )

    return [isMobile, isTablet, isDesktop]
}

const getControlStyles = ({ fixed, position, visible }) => {
    if (fixed) {
        if (!visible) {
            return 'display: none;'
        }

        return `${position}: -30px;`
    }

    if (position === 'left') {
        if (visible) {
            return 'left: 0;'
        }
        return 'left: -30px;'
    }

    if (visible) {
        return 'right: 0;'
    }

    return 'right: -30px;'
}

const Spacer = styled.div``

const StyledImagesSliderControlPrevent = styled.div`
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 9;
    opacity: 0;
    ${(props) =>
        props.position === 'left'
            ? 'left: 0;'
            : 'right: 0;'}
    top: 0;
    bottom: 0;
    margin: auto;
`

const StyledImagesSliderControl = styled.div`
    cursor: pointer;
    transition: 0.25s ease all;
    position: absolute;
    display: flex;
    ${getControlStyles}
    height: 30px;
    width: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
    z-index: 10;
    ${(props) =>
        props.position === 'left'
            ? 'border-top-right-radius: 2px; border-bottom-right-radius: 2px;'
            : 'border-top-left-radius: 2px; border-bottom-left-radius: 2px;'}
    ${getStyles(['carousel.control'])}
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    ${(props) =>
        props.position === 'left' &&
        'transform: rotate(180deg);'}

    top: 0;
    bottom: 0;
    margin: auto;
`

const StyledEyecatchersContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    ${(props) => (props.limitWidth ? 'padding: 0;' : '')}

    @media screen and (min-width: 900px) {
        padding-left: 0;
        padding-right: 0;
    }
`

const StyledImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    height: ${(props) => props.height[0]}px;

    @media screen and (min-width: 576px) {
        height: ${(props) => props.height[1]}px;
    }

    @media screen and (min-width: 900px) {
        justify-content: ${(props) =>
            props.center ? 'center' : 'space-between'};
        width: 450px;
        height: auto;
        /* height: ${(props) => props.height[2]}px; */
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`

const SwiperContainer = styled.div`
    padding-left: ${(props) =>
        props.limitWidth ? '0' : '0.75rem'};
    padding-right: ${(props) =>
        props.limitWidth ? '0' : '0.75rem'};
    width: ${(props) =>
        props.limitWidth
            ? `${props.dimensions[0][0]}px`
            : '100%'};
    // max-width: 100%;
    overflow: ${(props) =>
        props.limitWidth ? 'visible' : 'hidden'};
    position: relative;
    height: ${(props) => props.dimensions[0][1]}px;

    @media screen and (min-width: 576px) {
        height: ${(props) => props.dimensions[1][1]}px;
        width: ${(props) =>
            props.limitWidth
                ? `${props.dimensions[1][0]}px`
                : '100%'};
    }

    @media screen and (min-width: 900px) {
        height: ${(props) => props.dimensions[2][1]}px;
        padding-left: 0;
        padding-right: 0;
        width: ${(props) =>
            props.limitWidth
                ? `${props.dimensions[2][0]}px`
                : '340px'};
    }
`

export const Images = ({
    forcedImage,
    images,
    product,
    sliderDimensions,
}) => {
    const device = useSelector((state) => state.device)
    const queryMatches = useLayerMediaQuery()
    const [activeIndex, setActiveIndex] = useState(0)

    const ratio =
        sliderDimensions[0][0] / sliderDimensions[0][1]

    const thumbnailDimensions = sliderDimensions.map(() => [
        70,
        70 / ratio,
    ])

    const hasMultipleSlides = useSelector(
        (state) =>
            state.general?.productLayer?.sliderStyle
                ?.multipleSlides,
    )

    const isLimitWidth =
        images?.length === 1 || !hasMultipleSlides

    const thumbnailsData = useMemo(() => {
        const ratio =
            sliderDimensions[0][0] / sliderDimensions[0][1]
        const thumbnailDims = sliderDimensions.map(() => [
            70,
            70 / ratio,
        ])
        const thumbnailHeight = thumbnailDims[2][1]
        const spaceBeetween = 4
        const layerHeight = sliderDimensions[2][1]

        if (!layerHeight || !images?.length) {
            return {
                height: 0,
                width: thumbnailDims[2][0],
                amount: 0,
            }
        }

        if (
            images?.length *
                (thumbnailHeight + spaceBeetween) -
                spaceBeetween >
            layerHeight
        ) {
            const amountThumbnails = Math.floor(
                layerHeight / thumbnailHeight,
            )
            return {
                height:
                    amountThumbnails *
                        (thumbnailHeight + spaceBeetween) -
                    spaceBeetween,
                amount: amountThumbnails,
                width: thumbnailDims[2][0],
            }
        }

        return {
            height:
                images.length *
                    (thumbnailHeight + spaceBeetween) -
                spaceBeetween,
            amount: images.length,
            width: thumbnailDims[2][0],
        }
    }, [sliderDimensions, images])

    const handleSlideChange = ({ activeIndex: index }) => {
        setActiveIndex(index)
    }

    const handleThumbnailChange = (index) => {
        setActiveIndex(index)
    }

    const handleClickControl = (position) => () =>
        setActiveIndex(
            activeIndex + (position === 'left' ? -1 : 1),
        )

    const getDimensionsIndex = () => {
        const byMediaQuery = queryMatches.findIndex(Boolean)

        if (byMediaQuery === -1) {
            return [
                device === 'MOBILE',
                device === 'TABLET',
                device === 'DESKTOP',
            ].findIndex(Boolean)
        }

        return byMediaQuery
    }

    const dimensionsIndex = getDimensionsIndex()

    const swiperDimension =
        sliderDimensions[dimensionsIndex]

    const renderInner = () => {
        return (
            <>
                <Thumbnails
                    activeIndex={activeIndex}
                    height={thumbnailsData.height}
                    images={images}
                    thumbnailDimensions={
                        thumbnailDimensions
                    }
                    thumbnailsPerView={
                        thumbnailsData.amount
                    }
                    width={thumbnailsData.width}
                    onChange={handleThumbnailChange}
                />
                <SwiperContainer
                    dimensions={sliderDimensions}
                    limitWidth={isLimitWidth}
                >
                    <StyledEyecatchersContainer
                        limitWidth={isLimitWidth}
                    >
                        <Eyecatchers
                            layer
                            product={product}
                        />
                    </StyledEyecatchersContainer>
                    <StyledImagesSliderControlPrevent
                        dimensions={sliderDimensions}
                        position='left'
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    />
                    <StyledImagesSliderControl
                        data-testid='productLayer.images.slider.control'
                        dimensions={sliderDimensions}
                        fixed={isLimitWidth}
                        position='left'
                        visible={
                            images.length > 1 &&
                            activeIndex !== 0
                        }
                        onClick={handleClickControl('left')}
                    />
                    <StyledImagesSliderControlPrevent
                        dimensions={sliderDimensions}
                        position='right'
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    />
                    <StyledImagesSliderControl
                        data-testid='productLayer.images.slider.control'
                        dimensions={sliderDimensions}
                        fixed={isLimitWidth}
                        position='right'
                        visible={
                            images.length > 1 &&
                            activeIndex !==
                                images.length - 1
                        }
                        onClick={handleClickControl(
                            'right',
                        )}
                    />
                    <Swiper
                        activeIndex={activeIndex}
                        dimension={swiperDimension}
                        forcedImage={forcedImage}
                        images={images}
                        spaceBetween={4}
                        onSlideChange={handleSlideChange}
                    />
                </SwiperContainer>
                <Spacer />
            </>
        )
    }

    return images?.length > 0 ? (
        <StyledImagesContainer
            center={images?.length === 1}
            height={sliderDimensions.map((dim) => dim[1])}
            width={sliderDimensions.map((dim) => dim[0])}
        >
            {renderInner()}
        </StyledImagesContainer>
    ) : null
}

Images.propTypes = {
    forcedImage: PropTypes.string,
    images: PropTypes.array,
    imageDimensions: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    center: PropTypes.bool,
    isSSR: PropTypes.bool,
    sliderDimensions: PropTypes.array.isRequired,
}

Images.defaultProps = {
    center: false,
    isSSR: false,
    images: [],
    forcedImage: null,
}
