import { getData } from '../../api'

export const ProductActions = {
    fetch: (location) => (dispatch, getState) => {
        const device = getState().device
        const generalConfig = getState().general
        return getData(location, device).then((data) => {
            return dispatch({
                type: 'FETCH_SUCCESS',
                payload: {
                    model: {
                        ...data,
                        generalConfig,
                    },
                    location,
                },
            })
        })
    },
    fetchPrevious: (location) => (dispatch, getState) => {
        const device = getState().device
        const generalConfig = getState().general
        return getData(location, device).then((data) => {
            return dispatch({
                type: 'FETCH_PREV_SUCCESS',
                payload: {
                    model: {
                        ...data,
                        generalConfig,
                    },
                    location,
                },
            })
        })
    },
}
